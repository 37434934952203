import { redirect } from '@sveltejs/kit';
import { checkAuth } from '../lib/authorize';

// export const prerender = false
// export const ssr = false

export async function load({ url }) {
    await checkAuth();
    const redirects = {
        "current-listings/?type=available": '/properties',
        "current-listings/?type=contract": '/properties/in-contract',
        "current-listings/?type=sold": '/properties/sold',
        "product": "properties",
        "category/learn/": "communitychronicle/category/21/",
        "blog/": "communitychronicle/",
        "about-us/": "our-story/"
    }

    // handle 301 redirects for SEO
    let href = url.href;
    let path = url.pathname;
    console.log(path);

    for (const [key, val] of Object.entries(redirects)) {
        if (href.includes(key)) {
            throw redirect(301, path.replace(key, val));
        }
    }
}
